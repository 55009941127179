import React, { ReactNode, useEffect, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
  children: ReactNode;
  onClose?: () => void;
}

export default function Modal(props: Props) {
  const el = document.createElement("div");
  el.classList.add("modal-wrapper");

  const close = (evt: React.SyntheticEvent | KeyboardEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleKeypress = (evt: KeyboardEvent) => {
    if (evt.which == 27) {
      close(evt);
    }
  };

  useLayoutEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  });

  return createPortal(
    <>
      <div className="modal-background" onClick={close} />
      <div className="modal-content">
        <header>
          <div className="inner">
            <div className="logo">
              <a href="/">Galleri Riis</a>
            </div>
            <button className="close-modal" onClick={close}>
              Close
            </button>
          </div>
        </header>
        <main>
          <div className="inner">{props.children}</div>
        </main>
      </div>
    </>,
    el
  );
}
