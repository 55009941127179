import React from "react";

import useLightbox from "../hooks/useLightbox";
import useLimit from "../hooks/useLimit";
import Lightbox from "./Lightbox";
import Modal from "./Modal";
import Item from "./InstallationViewsGrid/Item";

interface Props {
  installation_views: InstallationView[];
}

export default function InstallationViewsGrid(props: Props) {
  const items = props.installation_views;

  const [limitedItems, limited, setExpanded] = useLimit(items, 9);

  const itemUrl = (item: InstallationView) => {
    return `#installation-views/${item.id}`;
  };

  const [shownItem, setShownItem, navigate] = useLightbox(items, itemUrl);

  const closeModal = (evt?: React.SyntheticEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setShownItem(null);
  };

  const handleExpand = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(true);
  };

  return (
    <div className="installation-views-grid">
      {shownItem && (
        <Modal onClose={closeModal}>
          <Lightbox image={shownItem.image} navigate={navigate}>
            {shownItem.caption && <div>{shownItem.caption}</div>}
          </Lightbox>
        </Modal>
      )}
      <div className="items">
        {limitedItems.map((i) => (
          <Item key={i.id} item={i} showItem={setShownItem} url={itemUrl(i)} />
        ))}
      </div>
      {limited && (
        <div className="buttons">
          <button className="view-more" onClick={handleExpand}>
            + View more
          </button>
        </div>
      )}
    </div>
  );
}
