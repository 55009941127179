import React, { ReactNode, useEffect, useState } from "react";

import Picture from "./Picture";

interface Props {
  image: Picture;
  videoUrl?: string;
  navigate: (offset: number) => void;
  children: ReactNode;
}

function embedUrl(url: string) {
  const vimeo =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const youtube =
    /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=))([\w-]{10,12})\b/;

  if (url.match(vimeo)) {
    const id = url.match(vimeo)[5];
    return `https://player.vimeo.com/video/${id}?autoplay=true`;
  }

  if (url.match(youtube)) {
    const id = url.match(youtube)[1];
    return `https://www.youtube.com/embed/${id}?autoplay=1&autohide=1&enablejsapi=1&version=3`;
  }
}

function videoEmbed(url: string) {
  return <iframe src={embedUrl(url)} frameBorder="0" />;
}

export default function Lightbox(props: Props) {
  const [loaded, setLoaded] = useState(props.videoUrl ? true : false);

  const handlePrevious = (evt: React.MouseEvent | KeyboardEvent) => {
    evt.preventDefault();
    props.navigate(-1);
  };

  const handleNext = (evt: React.MouseEvent | KeyboardEvent) => {
    evt.preventDefault();
    props.navigate(1);
  };

  const handleKeypress = (evt: KeyboardEvent) => {
    if (evt.which == 37) {
      handlePrevious(evt);
    }
    if (evt.which == 39) {
      handleNext(evt);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  });

  const handleLoaded = () => setLoaded(true);

  return (
    <div className="lightbox">
      <div className="lightbox-main">
        <button className="previous" onClick={handlePrevious}>
          Previous
        </button>
        <div className={"image " + (loaded ? "loaded" : "loading")}>
          <div className="image-container">
            {props.videoUrl && videoEmbed(props.videoUrl)}
            {!props.videoUrl && (
              <Picture image={props.image} onLoad={handleLoaded} />
            )}
          </div>
        </div>
        <button className="next" onClick={handleNext}>
          Next
        </button>
      </div>
      {props.children && (
        <div className="lightbox-footer">{props.children}</div>
      )}
    </div>
  );
}
