import React from "react";

interface Props {
  work: Work;
}

export default function WorkTitle(props: Props) {
  const { work } = props;

  return (
    <>
      {work.artist_name && (
        <div className="work-artist">{work.artist_name}</div>
      )}
      {work.title && <div className="work-title">{work.title}</div>}
      {work.technique && <div className="work-technique">{work.technique}</div>}
    </>
  );
}
